<template>
  <confirm-modal
    :title="$t('views.confirm.sanctionCountry.title')"
    :confirmMessage="$t('views.confirm.sanctionCountry.message')"
    :showConfirm="confirmModalVisible"
    @confirm:ok="sanctionCountry"
    @confirm:cancel="closeConfirmModal">
  </confirm-modal>
  <ListStyleWrapper>
    <sdCards :title="sanctioned ? $t('views.sanctionedCountries.sanctioned') : $t('views.sanctionedCountries.allowed')">
      <countries-list
        :countries="countries"
        :listFunction="sanctioned ? 'remove' : 'add'"
        view="sanctionedCountries"
        @addCountry="confirmSanctionCountry"
        @removeCountry="removeCountryFromSanctioned"
      />
    </sdCards>
  </ListStyleWrapper>
</template>

<script>
import {defineComponent, ref} from 'vue';
import {ListStyleWrapper} from '@/components/shared/cards/countriesListStyle';
import VueTypes from 'vue-types';
import _ from 'lodash';
import ConfirmModal from '@/components/modals/ConfirmModal';
import CountriesList from '@/components/shared/cards/CountriesList.vue';
import {useStore} from 'vuex';

export default defineComponent({
  name: 'SanctionedCountriesList',
  components: {
    ListStyleWrapper,
    ConfirmModal,
    CountriesList
  },
  props: {
    countries: VueTypes.array,
    sanctioned: VueTypes.bool.def(false)
  },
  setup(props) {
    const {dispatch} = useStore();

    const confirmModalVisible = ref(false);
    const countryToSanction = ref('');

    const confirmSanctionCountry = (countryCode) => {
      countryToSanction.value = countryCode;
      confirmModalVisible.value = true;
    };

    const closeConfirmModal = () => {
      confirmModalVisible.value = false;
      countryToSanction.value = '';
    };

    const sanctionCountry = async () => {
      dispatch('toggleLoading', true);
      await dispatch('sanctionACountry', {[_.snakeCase('countryCode')]: countryToSanction.value});
      dispatch('toggleLoading', false);
      closeConfirmModal();
    };

    const removeCountryFromSanctioned = async (country) => {
      dispatch('toggleLoading', true);
      await dispatch('deleteSanctionedCountry', country);
      dispatch('toggleLoading', false);
    };

    return {
      confirmModalVisible,
      confirmSanctionCountry,
      closeConfirmModal,
      sanctionCountry,
      removeCountryFromSanctioned
    };
  }
});
</script>

<style lang="scss" scoped>
.ant-card-body {
  text-align: center !important;
}
</style>
