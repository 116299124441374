<template>
  <div>
    <app-header :routes="routes" :buttons="buttons"
            @header-action="viewAffectedShipments" />
    <Main>
      <a-row :gutter="15">
        <a-col :md="12" :xs="24">
          <countries-list :countries="countries" />
        </a-col>
        <a-col :md="12" :xs="24">
          <countries-list :countries="sanctionedCountries" :sanctioned="true" />
        </a-col>
      </a-row>
    </Main>
  </div>
</template>

<script>
import {
  computed, defineComponent
} from 'vue';
import {useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {useAbility} from '@casl/vue';
import {Main} from '@/components/shared/styledComponents/Main';
import CountriesList from '@/components/sanctionedCountries/List';
import _ from 'lodash';

export default defineComponent({
  name: 'DynamicLists',
  components: {
    Main,
    CountriesList
  },
  setup() {
    const {
      state, dispatch, getters
    } = useStore();
    const router = useRouter();
    const routes = [{
      name: 'BackOfficeSanctionedCountries',
      breadcrumbName: 'views.sanctionedCountries.sanctioned'
    }];
    const countries = computed(() => getters.countries);
    const sanctionedCountries = computed(() => getters.sanctionedCountries);
    const isBackOffice = computed(() => state.session.userType === 'BackOfficeUser');
    const buttons = computed(() => {
      let btnsAction;
      if (isBackOffice.value && useAbility().can('read', 'affectedShipment') ) {
        btnsAction = [{
          action: 'viewAffectedShipments', icon: '', label: 'views.shipmentRequest.showAffectedShipments'
        }];
      }
      return btnsAction;
    });
    const viewAffectedShipments = () => router.push({name: 'BackOfficeShipments', params: {}});
    dispatch('toggleLoading', true);
    Promise.all([
      dispatch('getCountries', {[_.snakeCase('excludeSanctioned')]: true}),
      dispatch('getSanctionedCountries')
    ]).then(() => dispatch('toggleLoading', false));

    return {
      routes,
      buttons,
      viewAffectedShipments,
      countries,
      sanctionedCountries
    };
  }
});
</script>
